<template>
  <form
    class="sm:my-4 p-4 sm:rounded sm:shadow sm:max-w-xs mx-auto bg-white"
    @submit.prevent="submitYo"
  >
    <h2 class="pb-4" v-text="'Change Your Password'" />
    <mdc-textfield
      v-model="password"
      label="Password"
      type="password"
      class="w-full"
      required
      autocomplete="new-password"
    />
    <mdc-textfield
      v-model="password_confirmation"
      label="Confirm Password"
      type="password"
      class="w-full"
      required
      autocomplete="new-password"
    />
    <mdc-button
      :disabled="$store.state.loading"
      class="my-8"
      type="submit"
      ripple
      raised
    >
      Submit
    </mdc-button>
  </form>
</template>

<script>
import axios from '@/utils/xhr';
import MdcButton from '@/components/mdc/button.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';

export default {
  components: {
    MdcButton,
    MdcTextfield,
  },

  data: () => ({
    password: '',
    password_confirmation: '',
  }),

  methods: {
    submitYo() {
      this.error = null;
      axios
        .post('/account/change-password', {
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.$el.innerHTML = 'Your password has been updated.';
        });
    },
  },
};
</script>
